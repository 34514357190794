import React, { useEffect } from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import CTA from '../components/common/CTA'
import Layout from '../components/common/Layout'
import Topic from '../components/help/Topic'
import Article from '../components/help/Article'
import { categories } from '../data/categories'

export const SingleArticle = ({ data }) => {
  const title = data.page.frontmatter.title
  const subtitle = data.page.frontmatter.subtitle
  const image = data.page.frontmatter.featuredImage
  const heroType = data.page.frontmatter.heroType
  const embedLink = data.page.frontmatter.embedLink
  const body = data.page.html
  const next = data.page.frontmatter.next

  const docs = data.docs.edges
    .filter(({ node }) => node.frontmatter.template === 'SingleArticle')
    .flatMap(({ node }) => node.frontmatter)

  const guides = data.guides.edges
    .filter(({ node }) => node.frontmatter.template === 'SingleArticle')
    .flatMap(({ node }) => node.frontmatter)

  const isGuides = Boolean(
    data.guides.edges.find(
      ({ node }) => node.frontmatter.title === data.page.frontmatter.title
    )
  )

  return (
    <Layout
      title={title}
      meta={{
        description: subtitle,
        title: title,
        type: 'website'
      }}
    >
      <main>
        <Article
          title={title}
          subtitle={subtitle}
          image={image}
          body={body}
          docs={isGuides ? guides : docs}
          categories={isGuides ? categories['guides'] : categories['docs']}
          tag={isGuides ? 'guides' : 'docs'}
          embedLink={embedLink}
          heroType={heroType}
          next={next}
        />
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default SingleArticle

export const pageQuery = graphql`
  ## Query for SingleArticle data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleArticle($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        subtitle
        featuredImage
        embedLink
        heroType
        next {
          title
          description
          featuredImage
          link
        }
      }
    }

    docs: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "docs" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            template
            featuredImage
            featured
            topic
          }
        }
      }
    }
    guides: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "guides" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            template
            featuredImage
            featured
            topic
            embedLink
            heroType
          }
        }
      }
    }
  }
`
